import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withComponentInputBinding, withEnabledBlockingInitialNavigation } from '@angular/router';
import { appRoutes } from './app.routes';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { setPersistence } from '@firebase/auth';
import { browserSessionPersistence, getAuth, provideAuth } from '@angular/fire/auth';
import { firebaseConfig } from '@rc/typings/firebase';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MAT_RIPPLE_GLOBAL_OPTIONS, MatNativeDateModule, RippleGlobalOptions } from '@angular/material/core';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig } from '@angular/material/snack-bar';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TokenInterceptor } from '@rc/shared/token';

const globalRippleConfig: RippleGlobalOptions = {
	disabled: true,
	animation: {
		enterDuration: 0,
		exitDuration: 0,
	},
};
const globalSnackbarConfig: MatSnackBarConfig = {
	duration: 2500,
	horizontalPosition: 'center',
	verticalPosition: 'top',
};

export const appConfig: ApplicationConfig = {
	providers: [
		provideRouter(appRoutes, withEnabledBlockingInitialNavigation(), withComponentInputBinding()),
		importProvidersFrom(
			provideFirebaseApp(() => initializeApp(firebaseConfig)),
			provideAuth(() => {
				const auth = getAuth();
				setPersistence(auth, browserSessionPersistence);
				return auth;
			}),
			provideFirestore(() => getFirestore()),
			provideAnalytics(() => getAnalytics())
		),
		ScreenTrackingService,
		UserTrackingService,
		importProvidersFrom(MatNativeDateModule),
		{ provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
		{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
		{
			provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
			useValue: globalSnackbarConfig,
		},
		provideHttpClient(withInterceptorsFromDi()),
		provideAnimationsAsync()
	],
};
