import { inject } from '@angular/core';
import { Route, Router } from '@angular/router';
import { UserStore } from '@rc/rev-cyclone/user';
import { toObservable } from '@angular/core/rxjs-interop';
import { filter, map } from 'rxjs';
import { PracticeStore } from '@rc/rev-cyclone/practice';

export const appRoutes: Route[] = [
	{
		path: 'account',
		loadChildren: () => import('@rc/rev-cyclone/account').then((m) => m.ACCOUNT_ROUTES)
	},
	{
		path: '',
		loadChildren: () => import('@rc/rev-cyclone/core').then((m) => m.CORE_ROUTES),
		canActivate: [
			() => {
				const userStore = inject(UserStore);
				const router = inject(Router);

				return toObservable(userStore.loaded).pipe(
					filter((loaded) => loaded),
					map(() => {
						if (!userStore.isLoggedIn()) {
							router.navigate(['/account/signin']);
							return false;
						}
						if (!userStore.fbUser()?.emailVerified) {
							router.navigate(['/account/verify-email']);
							return false;
						}
						if (!userStore.mfaEnabled()) {
							router.navigate(['/account/mfa']);
							return false;
						}
						return true;
					})
				);
			},
			() => {
				const practiceStore = inject(PracticeStore);
				const router = inject(Router);

				return toObservable(practiceStore.loaded).pipe(
					filter((loaded) => loaded),
					map(() => {
						if (!practiceStore.hasAccess()) {
							router.navigate(['/account/access-denied']);
							return false;
						}
						return true;
					})
				);
			}
		]
	},
	{ path: '**', redirectTo: 'practice' }
];
